<template>
  <div class="doc-list">
      <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="doc" v-for="doc in list" :key="doc.id" @click="onClick(doc.id)">
          <van-image class="cover" :src="doc.cover" ></van-image>
          <p class="title">{{ doc.title }}</p>
          <p class="desc">{{ doc.desc }}</p>
          <div class="extra">
            <div class="user-info">
              <img class="avatar" v-image="doc.user.headimg" />
              <span class="username">{{ doc.user.nickname }}</span>
            </div>
            <div class="up">
<!--              <van-icon name="good-job-o" /> {{ doc.up }}-->
<!--              <van-icon name="good-job" />-->
              <van-icon name="eye-o" class="pv-icon" />{{ doc.pv }}
            </div>
          </div>
        </div>
      </van-list>
  </div>

</template>

<script>

import {
  ref,
  onMounted
} from 'vue';
import {useRouter} from 'vue-router';
import {Icon, Tag, Image, List} from 'vant';
import Baike from "@/models/Baike";

export default {
  setup() {
    const router = useRouter();
    const finished = ref(false);
    const loading = ref(false);
    const list = ref([]);
    const page = ref(1);
    const refreshing = ref(false)
    const size = 20;

    const onLoad = () => {
      if (loading.value) {
        return ;
      }
      loading.value = true;

      const docParam = {
        page: page.value,
        size: size
      }
      Baike.cosmeticIndex(docParam).then(res => {
        const dataList = res.data.list ? res.data.list : []
        if (page.value === 1) {
          list.value = dataList
        } else {
          dataList.forEach(value => {
            list.value.push(value)
          })
        }
        if (dataList.length < size) {
          finished.value = true;
        }
        page.value += 1;
        loading.value = false
        refreshing.value = false;
      })
    }
    onMounted(() => {
      onLoad()
    });
    const onClick = (id) => {
      router.push('/baike/docDetail?id='+id)
    }

    // docList.value = [
    //   {
    //     id: 1,
    //     title: '如何锻炼皮肤，提高猛药耐受度如何锻炼皮肤，提高猛药耐受度',
    //     cover: 'https://www.jeebei.com/images/brand/banner/drplant_banner.jpg',
    //     desc: '我们的口号是用猛药不翻车我们的口号是用猛药不翻车',
    //     up: 100,
    //     user: {
    //       username: '小月美妆',
    //       avatar: 'https://ar-try-image.zone1.meitudata.com/2452bb8f84730d63bc2380aa80656da1.jpg'
    //     }
    //   },
    //   {
    //     id: 2,
    //     title: '如何锻炼皮肤，提高猛药耐受度',
    //     cover: 'https://www.jeebei.com/images/brand/banner/annemarieborlind_banner.jpg',
    //     desc: '我们的口号是用猛药不翻车',
    //     up: 100,
    //     user: {
    //       username: '小月美妆',
    //       avatar: ''
    //     }
    //   },
    //   {
    //     id: 2,
    //     title: '如何锻炼皮肤，提高猛药耐受度',
    //     cover: 'https://www.jeebei.com/images/brand/banner/yuesai_banner.jpg',
    //     desc: '我们的口号是用猛药不翻车',
    //     up: 100,
    //     user: {
    //       username: '小月美妆',
    //       avatar: ''
    //     }
    //   }
    // ]
    return {
      refreshing,
      finished,
      loading,
      list,
      onLoad,
      onClick
    };
  },
  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Image.name]: Image,
    [List.name]: List
  }
};
</script>
<style scoped>

.doc {
  text-align: center;
  clear: both;
  border-bottom: solid #f6f6f6 1px;
  padding: 20px 0 10px;
}
.doc .cover {
  max-height: 100px;
}
.doc .title {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
}
.doc .desc {
  text-align: left;
}
.doc .extra {
  text-align: left;
  line-height: 32px;
  vertical-align: middle;
}
.doc .user-info {
  display: inline-block;
}
.doc .user-info .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  float: left;
}
.doc .user-info .username {
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
}
.doc .up {
  float: right;
}
.doc .pv-icon {
  font-size: 18px;
}


</style>
