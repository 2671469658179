import API from '../global/Resource';
import HTTP from '../global/SafeRequest';

export default {
    //经期百科
    jqBaike(param){
        return HTTP.get(API.urls.baike.jqbaike, param);
    },
    docDetail(param){
        return HTTP.get(API.urls.baike.docDetail, param);
    },
    cosmeticIndex(param){
        return HTTP.get(API.urls.baike.cosmeticIndex, param);
    }
}
