<template>
  <div class="main">
    <van-sticky>
      <van-search
          class="search"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
          @click="onSearch"
      >
        <template #action>
          <div @click="onSearch()">查询</div>
        </template>
      </van-search>
    </van-sticky>

    <div class="section" v-if="hotMakeupList.length>0">
      <p class="label">热门成分</p>
      <div>
        <van-row v-for="row in hotMakeupList" :key="row" gutter="10">
          <van-col span="6" v-for="item in row" :key="item" @click="gotoMakeup(item.id)">
            <div  class="tag">
              <span class="text">{{ item.name }}</span>
            </div>
          </van-col>
        </van-row>
<!--        <span class="tag" v-for="item in hotMakeupList" :key="item" @click="gotoMakeup(item.id)">{{ item.name }}</span>-->
      </div>
    </div>

    <div class="section">
      <p class="label">精选护肤百科</p>
      <index-doc></index-doc>
    </div>
  </div>


</template>

<script>

import {
  ref,
  onMounted
} from 'vue';
import {useRouter} from 'vue-router';
import {Icon, Tag, Col, Row, Search, Sticky, Image} from 'vant';
import Product from "../../models/Product";
import IndexDoc from "./components/IndexDoc";

export default {
  setup() {
    const router = useRouter();
    const hotMakeupList = ref([])

    const getHotMakeup = () => {
      Product.hotMakeup().then(res => {
        let list = []
        res.data.list.forEach((value, index) => {
          const m = parseInt(index/4)
          if (index%4 === 0) {
            list[m] = []
          }
          list[m][index%4] = value
        })
        hotMakeupList.value = list
      })
    }

    onMounted(() => {
      getHotMakeup()
    });
    const onSearch = () => {
      router.push('/product/presearch');
    }
    const gotoMakeup = (id) => {
      router.push('/product/makeup?id='+id)
    }

    return {
      hotMakeupList,
      onSearch,
      gotoMakeup
    };
  },
  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Col.name]:Col,
    [Row.name]:Row,
    [Search.name]: Search,
    [Sticky.name]: Sticky,
    [Image.name]: Image,
    IndexDoc
  }
};
</script>
<style scoped>

.search {
  height: 80px;
}

.section {
  background-color: #fff;
  margin-top: 8px;
  padding: 10px 15px;
}
.section .label {
  font-size: 18px;
  font-weight: 700;
  padding: 5px 0;
  margin: 0;
}
.section .tag {
  padding: 8px 4px;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  background-color: #f6f6f6;
  margin: 5px 0;
  text-align: center;
}
.section .tag .text {
  display: inline-block;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
}

</style>
